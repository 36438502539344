<template>
  <div class="box">
    <div v-for="item in askQuestionsList" :key="item.id" class="askQuestionsItem">
      <div class="leftBox">
        <div class="tagItem" :class="item.type == 3 ? '' : ''">
          {{ item.typeName }}
        </div>
        <div class="nameOrTimeItem">
          <div class="nameItem" v-html="ImgSizeLimit(item.subsetName)" />
          <div class="timeItem">
            <span>提问时间：{{ item.addTime }}</span>
            <span class="m-l-12 m-r-12">|</span>
            <span v-if="item.replyList && item.replyList.length > 0" class="textGreen">已回答</span>
            <span v-else>未回答</span>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="btnItem red" :class="item.type == 3 ? ' ' : ''" @click="openAskQuestions(item)">
          {{ item.type != 3 ? '我的提问' : '我的提问' }}
        </div>
        <!-- <div class="btnItem grey" @click="goCourse(item)">
                    {{ item.type == 1 ? '观看课程' : '查看试卷' }}
                </div> -->
        <div v-if="item.replyList.length > 0" class="btnItem grey"
          :class="item.replyList && item.replyList.length > 0 ? '' : 'noAnswer'" @click="openAskQuestions(item)">
          查看答案
        </div>
        <div v-else-if="item.type != 3" class="btnItem noAnswer">查看答案</div>
      </div>
    </div>
    <el-empty v-if="askQuestionsList.length == 0" description="暂无数据" />
    <!-- 分页 -->
    <el-pagination class="pagination partner-pagination" :current-page="page.pageNum" :page-size="page.pageSize"
      layout="sizes, prev, pager, next" :total="page.total" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
    <!-- 我的提问 -->
    <ask-questions-dialog ref="askQuestionsDialog" :dialog-visible="dialogVisible" @handleClose="handleClose" />
  </div>
</template>

<script>
import { selectStuPcQuestionsList } from '@/api/home.js'
import { getStudentWorkList, submitWork } from '@/api/myOp'
import askQuestionsDialog from './askQuestionsDialog.vue'
export default {
  components: {
    askQuestionsDialog
  },
  data() {
    return {
      askQuestionsList: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogVisible: false
    }
  },
  created() {
    this.selectStuPcQuestionsList()
  },
  methods: {
    selectStuPcQuestionsList() {
      const data = {
        ...this.page
      }
      delete data.total
      selectStuPcQuestionsList(data).then((res) => {
        res.rows.map((item) => {
          if (item.img) {
            item.img = item.img.split(',')
          }
        })
        this.page.total = res.total
        res.rows.forEach((item) => {
          if (item.type == 1) item.typeName = '课程'
          if (item.type == 2) item.typeName = '试题'
          if (item.type == 3) item.typeName = '作业'
        })
        this.askQuestionsList = res.rows
      })
    },
    /* 我的提问弹窗开/关 */
    openAskQuestions(item) {
      console.log(item)
      if (item.type != 3) {
        this.$refs.askQuestionsDialog.init(item)
        this.dialogVisible = true
      } else {
        this.$router.push(
          `/seventhPage/myOperationDetails?workId=${item.courseId}`
        )
      }
    },
    handleClose() {
      this.dialogVisible = false
    },

    /* 点击观看课程 */
    /* goCourse(item) {
                if (item.status == 1) {
                    this.$router.push({
                        ptah: '',
                        query: {

                        }
                    })
                } else if (item.status == 2) {
                    this.$router.push({
                        ptah: '/dianbofang',
                        query: {
                            id: item.id,
                            uservideoConfig: ,
                            courseId: item.courseId,
                            classId: item.classId,
                            groupId: item.groupId,
                            typebs: item.status,
                            classProductLine: item.classProductLine
                        }
                    })
                } else {
                    this.$router.push({
                        ptah: '',
                        query: {

                        }
                    })
                }
            }, */
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNum = 1
      this.selectStuPcQuestionsList()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.selectStuPcQuestionsList()
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  background-color: #ffffff;
  // width: 1220px;
  border-radius: 8px;
  // padding: 40px;

  .askQuestionsItem {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding: 40px 0;
    justify-content: space-between;
    align-items: center;

    .leftBox {
      display: flex;

      .tagItem {
        width: 38px;
        height: 18px;
        background: #e2eeff;
        opacity: 1;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #6ba8ff;
        text-align: center;
        line-height: 18px;
        margin-right: 12px;
      }

      .zuoye {
        color: #666666;
        background-color: #f2f2f2 !important;
      }

      .nameOrTimeItem {
        .nameItem {
          color: #333333;
          margin-bottom: 19px;
          width: 500px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
        }

        .timeItem {
          color: #999999;
          font-size: 14px;

          .textGreen {
            color: #3b9d4b;
          }
        }
      }
    }

    .rightBox {
      display: flex;
      justify-content: flex-end;

      .btnItem {
        width: 110px;
        height: 36px;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #b9bfc4;
        text-align: center;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #777a82;
        margin-right: 16px;
        cursor: pointer;
      }

      .red {
        border-color: #006de7;
        color: #006de7;
      }

      .grey {
        border-color: #b9bfc4;
        color: #777a82;
      }

      .noAnswer {
        border-color: #cccccc !important;
        color: #cccccc !important;
      }
    }
  }
}
</style>
