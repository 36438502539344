var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":"我的提问","visible":_vm.dialogVisible,"width":"700px","before-close":_vm.handleClose,"modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"box"},[(
          _vm.myAskQuestions &&
          _vm.myAskQuestions.status == 2 &&
          _vm.dialogVisible &&
          _vm.myAskQuestions.videoUrl.endsWith('mp4')
        )?_c('video',{staticClass:"video",attrs:{"controls":"","src":_vm.myAskQuestions.videoUrl}}):(
          _vm.myAskQuestions &&
          _vm.myAskQuestions.status == 2 &&
          _vm.myAskQuestions.videoUrl &&
          _vm.accessKeyId &&
          _vm.accessKeySecret &&
          _vm.dialogVisible
        )?_c('jxyplayer',{attrs:{"access-key-id":_vm.accessKeyId,"url":_vm.myAskQuestions.videoUrl,"access-key-secret":_vm.accessKeySecret,"options":_vm.option}}):_vm._e(),(_vm.liveInfo == 2)?_c('div',{staticClass:"live-box"},[_c('div',{attrs:{"id":"livePlayer"}}),_c('div',{attrs:{"id":"drawPanel"}})]):_vm._e(),(_vm.liveInfo == 1)?_c('div',{staticClass:"live-box"},[_c('div',{attrs:{"id":"playbackPlayer"}}),_c('div',{attrs:{"id":"playbackPanel"}})]):_vm._e(),(_vm.myAskQuestions && _vm.myAskQuestions.status == 4)?_c('div',{staticClass:"testPaperBox"},[_c('div',{staticClass:"topicBox m-b-20"},[(_vm.myAskQuestions.composeTitle)?_c('div',{staticClass:"tag"},[_vm._v("组合题")]):_vm._e(),_c('div',{staticClass:"topic"},[_vm._v(_vm._s(_vm.myAskQuestions.composeTitle))])]),_c('div',{staticClass:"topicBox"},[(_vm.myAskQuestions.questionSubjectVO.questionType == 0)?_c('div',{staticClass:"tag"},[_vm._v(" 单选 ")]):(_vm.myAskQuestions.questionSubjectVO.questionType == 1)?_c('div',{staticClass:"tag"},[_vm._v(" 多选 ")]):(_vm.myAskQuestions.questionSubjectVO.questionType == 2)?_c('div',{staticClass:"tag"},[_vm._v(" 判断 ")]):(_vm.myAskQuestions.questionSubjectVO.questionType == 3)?_c('div',{staticClass:"tag"},[_vm._v(" 填空 ")]):_c('div',{staticClass:"tag"},[_vm._v("主观")]),_c('div',[_c('div',{staticClass:"topic",domProps:{"innerHTML":_vm._s(
                _vm.ImgSizeLimit(_vm.myAskQuestions.questionSubjectVO.questionTitle)
              )}}),_vm._l((_vm.questionOption),function(question,index){return _c('div',{key:question,staticClass:"radioBox"},[_c('span',{staticClass:"m-r-8"},[_vm._v(_vm._s(_vm.options[index]))]),_c('el-radio',{attrs:{"disabled":""}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(question))}})],1)})],2)])]):_vm._e(),(_vm.dialogVisible)?_c('askQues',{attrs:{"type":_vm.myAskQuestions.type,"is-purchase":true,"status":_vm.myAskQuestions.status,"showId":_vm.showId,"course-id":_vm.myAskQuestions.courseId,"chapter-id":_vm.myAskQuestions.chapterId}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }